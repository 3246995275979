

export default function InputComponent({ placeholder, value, Onchange, type }) {

    return (
        <div>
            <input placeholder={placeholder}
                value={value}
                onChange={Onchange}
                className='outline-none border-2 rounded-md w-[20rem] h-10 focus:border-orange-200 duration-200 pl-2'
                type={type}
            />
        </div>
    )
}