import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
export default function Charts({ data, type }) {
    let chartData = [];

    if (type === 'nfce') {
        chartData = data.map((item, index) => ({
            id: index,
            value: item.total,
            label: item.modelo === '55' ? 'NFe' : 'NFCe'
        }));
    } else {
        chartData = data.map((item, index) => ({
            id: index,
            value: item.total,
            label: item.modelo === '55' ? 'NFe' : 'NFCe'
        }));

        // Caso existam outros tipos de dados, você pode adicionar aqui
    }

    return (
        <div>
            {
                data.length === 0 ?
                    <p className='text-center text-sm mt-6'>Nenhum dado disponivel</p> :
                    <PieChart
                        series={[
                            {
                                arcLabel: (item) => `${item.label} (${item.value})`,
                                arcLabelMinAngle: 45,
                                data: chartData
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontWeight: 'bold',
                            },
                        }}
                        width={250}
                        height={200}
                    />

            }

        </div>

    )
}