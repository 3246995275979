import { Card, Checkbox } from "@mui/material"
import { X, SquareUser, SearchIcon } from "lucide-react"
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../services/api";
export default function FormUser({ setOpen,
    email, setEmail,
    name, setName,
    adress, setAdress,
    phone, setPhone,
    city, setCity,
    cep, setCep,
    uf, setUf,
    atv, setAtv,
    intv, setIntv,
    superv, setSuperv,
    setCounter, counter,
    selected,
    tipo,
    ativo,
    setTipo,
    setAtivo,
    reload, setReload
}) {


    const [loading, setLoading] = useState(false)

    //armazena os estados do braisl 
    const UFsBrasil = [
        { id: 'AC', label: 'Acre' },
        { id: 'AL', label: 'Alagoas' },
        { id: 'AP', label: 'Amapá' },
        { id: 'AM', label: 'Amazonas' },
        { id: 'BA', label: 'Bahia' },
        { id: 'CE', label: 'Ceará' },
        { id: 'DF', label: 'Distrito Federal' },
        { id: 'ES', label: 'Espírito Santo' },
        { id: 'GO', label: 'Goiás' },
        { id: 'MA', label: 'Maranhão' },
        { id: 'MT', label: 'Mato Grosso' },
        { id: 'MS', label: 'Mato Grosso do Sul' },
        { id: 'MG', label: 'Minas Gerais' },
        { id: 'PA', label: 'Pará' },
        { id: 'PB', label: 'Paraíba' },
        { id: 'PR', label: 'Paraná' },
        { id: 'PE', label: 'Pernambuco' },
        { id: 'PI', label: 'Piauí' },
        { id: 'RJ', label: 'Rio de Janeiro' },
        { id: 'RN', label: 'Rio Grande do Norte' },
        { id: 'RS', label: 'Rio Grande do Sul' },
        { id: 'RO', label: 'Rondônia' },
        { id: 'RR', label: 'Roraima' },
        { id: 'SC', label: 'Santa Catarina' },
        { id: 'SP', label: 'São Paulo' },
        { id: 'SE', label: 'Sergipe' },
        { id: 'TO', label: 'Tocantins' }
    ];

    //quando o usuario digitar o cep, ele ja pesquisa  e preenche cidade e estado
    async function fetchAddressInfo() {
        if (cep !== '') {
            if (cep.length >= 8) {
                // Remove os hífens do CEP
                const formattedCep = cep.replace('-', '');

                const response = await axios.get(`https://viacep.com.br/ws/${formattedCep}/json/`);

                if (response.data) {
                    const { localidade, uf, logradouro } = response.data;


                    setCity(localidade);
                    setUf(uf);
                    setAdress(logradouro)

                } else {

                }
            }
        }

    }

    // se tiver um usuario selecionado ele preenche todos os campos
    useEffect(() => {
        if (selected !== '') {
            setName(selected.NOME)
            setEmail(selected.CODUSUARIO || '')
            setPhone(selected.TELEFONE || '')
            setCity(selected.CIDADE || '')
            setUf(selected.UF || '')
            setAdress(selected.ENDERECOS || '')
            setCep(selected.CEP || '')

            if (selected.ATIVO === 'S') {
                setAtv(true)
            } else {
                setAtv(false)
            }

            if (
                selected.TIPO === 'S'

            ) {
                setSuperv(true)
                setCounter(false)
            } else {
                setCounter(true)
                setSuperv(false)
            }



        } else {
            setName('')
            setEmail('')
            setPhone('')
            setCity('')
            setUf('')
            setAdress('')
            setCep('')

        }

    }, [])


    async function RegisterUser() {

        if (name !== '' && email !== '' && phone !== '' && adress !== '' && cep !== '' && city !== '' && uf !== '' && ativo !== '' && counter !== '') {
            setLoading(true)

            const data = {
                Email: email,
                Nome: name.toUpperCase(),
                Endereco: adress.toUpperCase(),
                Telefone: phone,
                Tipo: tipo,
                Ativo: ativo,
                Cidade: city.toUpperCase(),
                CEP: cep,
                UF: uf.toUpperCase()
            }
            await axios.post(`${api}usuario`, data)
                .then((res) => {
                    toast.success('Usuário cadastrado com sucesso')
                    setLoading(false)
                    setOpen(false)
                    setReload(!reload)
                })
                .catch((error) => {
                    toast.error('Erro ao cadastrar usuário')
                    setLoading(false)
                })


        } else {
            toast.warn('Preencha os campos vazios')
        }

    }


    async function UpdateUser() {
        setLoading(true)
        const data = {
            Email: email,
            Nome: name.toUpperCase(),
            Endereco: adress.toUpperCase(),
            Telefone: phone,
            Tipo: tipo,
            Ativo: ativo,
            Cidade: city.toUpperCase(),
            CEP: cep,
            UF: uf.toUpperCase()
        }

        await axios.put(`${api}usuario/${selected.CODIGO}`, data)
            .then((res) => {
                setLoading(false)
                toast.success('Dados atualizados com sucesso')
                setOpen(false)
                setReload(!reload)
            }).catch((error) => {
                toast.error('Erro ao atualizar  usuário')
                setLoading(false)

            })
    }

    return (
        <div className="w-[35rem] h-[30rem] bg-white  mx-4 mt-3 ">
            <div className=" mx-4 mt-3 ">

                <div className="flex justify-between">
                    <Card className="p-1">
                        <SquareUser
                            className="text-gray-700 cursor-pointer"
                        />
                    </Card>

                    <X
                        className="text-gray-700 cursor-pointer"
                        onClick={() => {
                            setOpen(false)
                            setName('')
                            setEmail('')
                            setPhone('')
                            setCity('')
                            setUf('')
                            setAdress('')
                            setCep('')

                        }}
                    />
                </div>
                <div className="my-4">
                    <h2 className="font-semibold ">Adicionar Usuário</h2>
                    <span className='text-sm text-gray-400'>Preencha todos os campos</span>
                </div>

            </div>

            <div className="mx-4 flex items-center justify-center">
                <form className="  gap-2 grid grid-cols-2" >


                    <div className="flex-col gap-1 ">
                        <p className="font-semibold text-sm">Nome</p>
                        <input
                            type='text'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className='outline-none border-2 px-4 py-1 rounded-md'


                        />
                    </div>
                    <div className="flex-col gap-1">
                        <p className="font-semibold text-sm">Email</p>
                        <input
                            type='text'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}

                            className='outline-none border-2 px-4 py-1 rounded-md'

                        />
                    </div>
                    <div className="flex-col gap-1">
                        <p className="font-semibold text-sm">Telefone</p>
                        <input
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}

                            className='outline-none border-2 px-4 py-1 rounded-md'

                        />
                    </div>
                    <div className="flex-col gap-1 relative">
                        <p className="font-semibold text-sm">CEP</p>
                        <input

                            value={cep}
                            onChange={(e) => setCep(e.target.value)}
                            className='outline-none border-2 px-4 py-1 rounded-md'

                        />
                        <SearchIcon size={20} className='text-orange-500 absolute right-4 top-7 cursor-pointer'
                            onClick={fetchAddressInfo}

                        />
                    </div>
                    <div className="flex-col gap-1">
                        <p className="font-semibold text-sm">Endereço</p>
                        <input
                            type='text'
                            value={adress}
                            onChange={(e) => setAdress(e.target.value)}
                            className='outline-none border-2 px-4 py-1 rounded-md'

                        />
                    </div>


                    <div className="flex-col gap-1">
                        <p className="font-semibold text-sm">Cidade</p>
                        <input
                            type='text'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className='outline-none border-2 px-4 py-1 rounded-md'

                        />
                    </div>


                    <div className="flex-col gap-1">
                        <p className="font-semibold text-sm">Estado</p>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={uf}
                            onChange={(e) => setUf(e.target.value)}
                            autoWidth
                            label="Age"
                            className="px-4 h-10"
                        >
                            {UFsBrasil.map((uf) => (
                                <MenuItem key={uf.id} value={uf.id}>{uf.id}</MenuItem>
                            ))}

                        </Select>
                    </div>
                    <div className="flex items-center justify-center gap-0">
                        <div>
                            <label>Ativo</label>
                            <Checkbox
                                checked={atv}
                                color='warning'
                                onClick={() => {
                                    setAtv(!atv)

                                }}
                            />
                        </div>
                        <div>
                            <label>Supervisor</label>
                            <Checkbox
                                checked={superv}
                                color='warning'
                                onClick={() => {
                                    setSuperv(true)
                                    setCounter(false)
                                    setTipo('S')

                                }}
                            />
                        </div>
                        <div>
                            <label>Contador</label>
                            <Checkbox
                                checked={counter}
                                color='warning'
                                onClick={() => {
                                    setCounter(true)
                                    setTipo('C')
                                    setSuperv(false)
                                }}
                            />
                        </div>

                    </div>
                </form>
            </div>
            <div className="flex gap-4 mt-6 items-center justify-center">
                <button
                    className="bg-white text-black min-w-[12rem] py-2 rounded-md border-2 
                    hover:text-red-500 duration-300
                    "
                    onClick={() => setOpen(false)}
                >

                    Cancelar
                </button>
                <button
                    className="bg-orange-500 text-white min-w-[12rem] py-2 rounded-md 
                    hover:bg-orange-300 duration-300
                    "
                    onClick={() => {
                        if (selected !== '') {
                            UpdateUser()
                        } else { RegisterUser() }
                    }}
                >
                    {
                        loading ?
                            <CircularProgress color='inherit' size={20} /> :
                            'Salvar'
                    }
                </button>
            </div>
        </div>

    )
}