import { SearchIcon } from "lucide-react"

export default function SearchBar({ search, setSearch, onClick, placeholder }) {
    return (

        <div className="flex gap-1 max-md:grid grid-cols-1 ">
            <div className='flex gap-1 relative'>
                <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={placeholder}
                    className="outline-none  w-[15rem] border-2 rounded-md text-sm"

                />

                <div className="bg-orange-500 inline-block p-1 rounded-lg cursor-pointer
                    hover:bg-orange-300 duration-300
                    ">
                    <SearchIcon
                        size={20}
                        color='white'
                        onClick={onClick}
                    />

                </div>
            </div>
        </div>
    )
}