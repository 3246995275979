import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { context } from '../contexts';

export default function Private({ children }) {
    const { loading, signed } = useContext(context);

    // Exibe um carregando enquanto o estado está sendo verificado
    if (loading) {
        return <div>Carregando...</div>;
    }

    // Se não estiver assinado, redireciona para a página de login
    if (!signed) {
        return <Navigate to="/" />;
    }

    // Se tudo estiver certo, renderiza os filhos
    return children;
}
