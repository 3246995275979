function FormatCurrency(valor) {
    // Verifica se o valor é um número
    if (isNaN(valor)) {
        throw new Error('O valor fornecido não é um número.');
    }

    // Converte o valor para o formato de número com duas casas decimais
    const numeroFormatado = parseFloat(valor).toFixed(2);

    // Converte o número para uma string formatada como valor monetário
    const valorMonetario = numeroFormatado.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    // Retorna o valor monetário formatado
    return `R$ ${valorMonetario}`;
}

export default FormatCurrency;