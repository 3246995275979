import { Route, Routes } from "react-router-dom"
import Login from "../pages/Login"
import RecoverPassword from "../pages/Login/recoverPassword"
import Home from './../pages/home/index';
import Clients from './../pages/clients/index';
import BondClients from './../pages/bondClients/index';
import Bonds from "../pages/bondClients/bonds";
import Users from "../pages/users";
import Notes from './../pages/notes/index';
import ReportSintetic from './../pages/notes/reportSintetic';
import ReportAnalytcal from "../pages/notes/ReportAnalitcal";
import ResumeFiscal from './../pages/notes/resumeFiscal';
import PrintComponent from './../components/PrintComponent';
import Private from './private';
import NoteCancelled from "../pages/notes/noteCancelled";
export default function Routers() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/recoverPassword" element={<RecoverPassword />} />
            <Route path="/home" element={<Private> <Home /> </Private>} />
            <Route path="/clients" element={<Private><Clients /></Private>} />
            <Route path="/boundClients" element={<BondClients />} />
            <Route path="/bonds" element={<Private> <Bonds /> </Private>} />
            <Route path="/users" element={<Private><Users /></Private>} />
            <Route path="/notes" element={<Private><Notes /></Private>} />
            <Route path="/reportSintetic" element={<Private><ReportSintetic /></Private>} />
            <Route path="/reportAnalitycal" element={<Private><ReportAnalytcal /></Private>} />
            <Route path="/resumeFiscal" element={<Private><ResumeFiscal /></Private>} />
            <Route path="/Print" element={<Private><PrintComponent /></Private>} />
            <Route path="/cancelled" element={<Private><NoteCancelled /></Private>} />


        </Routes>

    )
}