
import { DataGrid } from "@mui/x-data-grid";
import { Pencil, TrashIcon } from "lucide-react";
import FormatCnpj from "../helpers/FormatCnpj";


export default function TableClients({ data, type, selected, setSelected, open, setOpen, DeleteClient, setConfirmDelete }) {


    //percorre data e adiciona um id


    //colunas clientes
    const columns = [
        { field: 'CODIGO', headerName: 'Código', width: 70 },
        { field: 'NOME', headerName: 'Nome', width: 250 },
        { field: 'CNPJ', headerName: 'CNPJ', width: 150 },
        { field: 'CIDADE', headerName: 'Cidade', width: 200 },
        { field: 'UF', headerName: 'UF', width: 130 },


        {
            field: 'Excluir',
            headerName: 'Excluir',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div className="flex text-center mt-4"
                    onClick={() => {
                        setSelected(params.row)
                        DeleteClient(true)
                    }}
                >
                    <TrashIcon size={20}
                        className='text-red-500 cursor-pointer'
                    />
                </div>
            ),
        },
        {
            field: 'Editar',
            headerName: 'Editar',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div className="flex text-center mt-4"
                    onClick={() => {
                        setSelected(params.row);
                        setOpen(true)
                    }}
                >
                    <Pencil
                        size={20}
                        className='text-blue-500 cursor-pointer'
                    />
                </div>
            ),
        }


    ];

    //colunas usuarios
    const columnsUsers = [
        { field: 'CODIGO', headerName: 'Código', width: 70 },
        { field: 'CODUSUARIO', headerName: 'Login', width: 250 },
        { field: 'NOME', headerName: 'Nome', width: 250 },
        { field: 'TELEFONE', headerName: 'Telefone', width: 150 },
        { field: 'CIDADE', headerName: 'Cidade', width: 200 },



        {
            field: 'Excluir',
            headerName: 'Excluir',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div className="flex text-center mt-4"
                    onClick={() => {
                        setSelected(params.row)
                        setConfirmDelete(true)
                    }}
                >
                    <TrashIcon size={20}
                        className='text-red-500 cursor-pointer'
                    />
                </div>
            ),
        },
        {
            field: 'Editar',
            headerName: 'Editar',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div className="flex text-center mt-4"
                    onClick={() => {
                        setSelected(params.row)
                        setOpen(true)
                    }}
                >
                    <Pencil
                        size={20}
                        className='text-blue-500 cursor-pointer'
                    />
                </div>
            ),
        }


    ];
    const rows = data.map((item) => ({
        ...item,
        id: item.CODIGO,
        CNPJ: FormatCnpj(item.CNPJ)
    }))
    const rowsUsers = data.map((item) => ({
        ...item,
        id: item.CODIGO,

    }))
    return (
        <div className="my-4 h-[450px]  ">
            <DataGrid
                className='max-lg:w-2/3 max-md:w-1/2 max-sm:w-1/3 '

                rows={type === 'clients' ? rows : rowsUsers}
                columns={type === 'clients' ? columns : columnsUsers}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20]}
                localeText={{
                    footerPaginationRowsPerPage: 'Linhas por página:',
                    footerPaginationPage: 'Página:',
                    footerPaginationNextPage: 'Próxima página',
                    footerPaginationPreviousPage: 'Página anterior',
                    footerRowSelected: (count) => `${count.toLocaleString()} linha(s) selecionada(s)`,
                }}

            />
        </div>
    )
}