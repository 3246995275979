import { createContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import api from './../services/api';
import { toast } from 'react-toastify';
import FormatDate from "../helpers/FormatDate";
import { format } from 'date-fns'
export const context = createContext()

export default function ContextAuth({ children }) {
    const [open, setOpen] = useState(false)
    const [userData, setUserData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [signed, setSigned] = useState(false)
    const navigate = useNavigate()

    //armazena a nota selecionada
    const [selected, setSelected] = useState([])
    function FormatDateMounth(value) {
        const formated = format(value, 'MM')
        return formated
    }
    function FormatDateYear(value) {
        const formated = format(value, 'yy')
        return formated
    }

    // realize o login e armazena os dados
    async function Login(user, password) {
        setLoading(true)
        const data = {

            login: user,
            password: password

        }

        await axios.post(`${api}login`, data)
            .then((res) => {

                setLoading(false)
                if (res.data.retorno === true) {
                    const data = res.data;
                    const user = {
                        usuario: data.usuario,
                        tipo: data.tipo,
                        mensagem: data.mensagem,
                        retorno: data.retorno
                    }
                    setUserData(res.data)
                    // console.log(userData)
                    localStorage.setItem('@user', JSON.stringify(user))
                    setSigned(true)
                    navigate('/home')
                } else {
                    toast.error('Usuário ou senha incorretos')
                }
            })
            .catch((error) => {

                toast.error('Erro ao realizar login')
                setSigned(false)
                setLoading(false)
            })

    }


    useEffect(() => {
        async function VerifyUser() {
            const userData = localStorage.getItem('@user');
            console.log('verificando usuario', userData)
            if (userData) {
                const data = JSON.parse(userData)


                setUserData(data);
                setSigned(true); // Defina o estado signed como true se houver dados de usuário salvos
                navigate('/home')

            } else {
                setSigned(false)
            }
            setLoading(false); // Indique que a verificação foi concluída
        }
        VerifyUser();
    }, []);


    const [listCounters, setListCounters] = useState([])
    const [filteredList, setFilteredList] = useState([])
    async function getCounters() {
        setLoading(true)
        await axios.get(`${api}vinculo`)
            .then((res) => {
                setFilteredList(res.data)
                setListCounters(res.data)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                toast.error('Erro ao buscar contadores')
            })


    }

    //armazena as notas de forma global pra ser usada no relatorio
    const [notesGlobal, setNoteGlobal] = useState([])
    const [consistencia, setConsistencia] = useState('')
    const [dateConsistencia, setDateConsistencia] = useState('')
    //armazena a chave de aceso
    const [keyNote, setKeyNote] = useState('')

    const [dashboardData, setDashboardData] = useState([])
    const [date, setDate] = useState(new Date())
    const [clients, setClients] = useState([])
    async function getDashboard() {


        await axios.get(`${api}dashboard/${userData.usuario}/${userData.tipo}/${FormatDateMounth(date)}/${FormatDateYear(date)}`)
            .then((res) => {
                setDashboardData(res.data);

            })


            .catch((error) => {
                toast.error('Erro ao buscar dados do dashboard');
                console.error('Erro ao buscar dados do dashboard', error);

            })
    }
    async function getClients() {


        await axios.get(`${api}dashboard/${userData.usuario}`)
            .then((res) => {

                setClients(res.data)
            }).catch((error) => {

                toast.error('Erro ao buscar clientes vinculados')
            })
    }
    //quando forita a impressao de uma nota
    // do tipo nfce, sera armazenado aqui nessa const, se ela esta cancelada ou nao
    const [tiponfce, setTipoNfce] = useState('')

    // se a nota for cancelada ela gera um xml evento
    // o xml evento e armazenado nessa const.
    const [xmlEvento, setXmlEvento] = useState('')

    return (
        <context.Provider value={{
            open, setOpen, selected, setSelected, Login,
            loading, userData, getCounters, setFilteredList, filteredList, listCounters, setListCounters, signed, notesGlobal, setNoteGlobal,
            keyNote, setKeyNote, consistencia, setConsistencia, dateConsistencia, setDateConsistencia, dashboardData, setDashboardData, date, setDate, getDashboard,
            clients, setClients, getClients, tiponfce, setTipoNfce, xmlEvento, setXmlEvento
        }}>
            {children}
        </context.Provider>

    )
}