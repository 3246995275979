import Header from "../../components/header/Header";
import { useState, useContext } from 'react';
import { EllipsisIcon, SearchIcon } from "lucide-react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { EllipsisVertical } from 'lucide-react';
import TableUserBond from "../../components/tableUsersBond";
import { Link } from "react-router-dom";
import SearchBar from "../../components/Searchbar";
import axios from 'axios';
import api from './../../services/api';
import { toast } from "react-toastify";
import { useEffect } from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import { context } from "../../contexts";

export default function BondClients() {
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('Cliente')
    const [anchorEL, setAnchorEl] = useState(null)
    const [reload, setReload] = useState(false)
    const { filteredList, getCounters, listCounters, setListCounters, setFilteredList, loading, setLoading } = useContext(context)
    const open = Boolean(anchorEL)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = (e) => {
        setAnchorEl(null)
    }

    //dados ficticios




    useEffect(() => {
        getCounters()
    }, [])
    useEffect(() => {
        getCounters()

    }, [reload])

    //verifica se o termo pesquisado e um numero
    function isNumber(value) {
        return /^\d+$/.test(value)

    }
    function Search() {
        if (filter === 'Usuario') {
            SearchUser()
        } else {
            SearchClient()
        }
    }
    function SearchUser() {


        const filters = listCounters.filter((item) => item.CODUSUARIO.toUpperCase().includes(search.toUpperCase()))
        if (filters.length > 0) {
            setFilteredList(filters)
        } else {
            setFilteredList([])
        }


    }
    function SearchClient() {

        if (isNumber(search)) {
            const filters = listCounters.filter((item) => item.CNPJ && item.CNPJ.includes(search))
            if (filters.length > 0) {
                setFilteredList(filters)
            } else {

                setFilteredList([])
            }

        } else {
            const filters = listCounters.filter((item) => item.NOME && item.NOME.toUpperCase().includes(search.toUpperCase()));
            if (filters.length > 0) {
                setFilteredList(filters)
            } else {
                setFilteredList([])
            }

        }
    }

    useEffect(() => {
        if (search === '') {
            setFilteredList(listCounters)
        }
    }, [search])


    return (
        <div className="flex h-[100vh] w-[100vw] overflow-x-hidden ">
            <Header />

            <div className='mx-8 my-8 max-md:mt-14'>
                {/**Searchbar */}
                <p className="font-semibold my-2">Vínculos</p>
                <div className="flex gap-1 max-md:grid grid-cols-1 ">
                    <div className='flex gap-1 relative'>
                        <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder='Pesquisar'
                            className="outline-none  w-[15rem] border-2 rounded-md text-sm"

                        />
                        <EllipsisVertical size={20} className='absolute -right-6 top-1 cursor-pointer'
                            onClick={handleClick}
                        />
                        <div className="bg-orange-500 inline-block p-1 rounded-lg cursor-pointer
                    hover:bg-orange-300 duration-300
                
                    "
                            onClick={Search}
                        >
                            <SearchIcon
                                size={20}
                                color='white'
                            />

                        </div>
                    </div>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEL}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            className={`${filter === 'Cliente' ? 'bg-gray-400' : ''} text-xs`}

                            onClick={() => {
                                setFilter('Cliente')
                                handleClose()
                            }}>Cliente</MenuItem>
                        <MenuItem
                            className={`${filter === 'Usuario' ? 'bg-gray-400' : ''} text-xs`}
                            onClick={
                                () => {
                                    setFilter('Usuario')
                                    handleClose()
                                }
                            }>Usuário</MenuItem>

                    </Menu>

                    {
                        /**botao para adicionar novo cliente */
                    }
                    <Link to={'/bonds'}>
                        <button className='mx-12 bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[12rem] min-h-7
                    '

                        >
                            Novo Vínculo
                        </button>

                    </Link>

                </div>

                {


                    loading ?
                        <div className='absolute left-[50%] top-[50%]'>
                            <CircularProgress color='warning' />
                        </div> : <TableUserBond data={filteredList} setReload={setReload} />




                }

            </div>

        </div >

    )
}