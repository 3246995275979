import { Button, Input } from '@mui/material';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import InputComponent from '../../components/InputComponent';
import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';
import { toast } from 'react-toastify';
import { context } from '../../contexts';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
export default function Login() {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [visible, setVisible] = useState(false)
    const { Login, loading } = useContext(context)

    function RunLogin(e) {
        e.preventDefault()
        if (user !== '' && password !== '') {
            Login(user, password)
        } else {
            toast.warn('Preencha os campos vazios')
        }
    }

    return (


        <div>

            {
                loading ?

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}


                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> : ''
            }
            <div className="flex w-full h-full relative max-md:flex-col" >



                {/**imagem */}
                <div className=" bg-gradient-to-r from-orange-300 to-orange-500 w-[50%] h-[100vh] max-md:hidden">
                    <div className="absolute inset-0 bg-cover bg-left opacity-30 w-[50%] blur-[3px]"
                        style={{ backgroundImage: 'url(https://i0.wp.com/revistaanefac.org.br/wp-content/uploads/2022/06/contador-para-contador.jpg?fit=2121%2C1414&ssl=1)' }}>
                    </div>
                    <div className="flex flex-col items-center justify-center z-50 text-white relative mt-36 gap-6">
                        <img src={require('../../assets/LOGO_HORIZONTAL_NEGATIVO.png')}
                            alt='Logo foco sistemas'
                            className="w-[20rem] h-auto " />
                        <div>
                            <p className='max-w-[30rem] font-semibold text-2xl'>Bem-vindo ao Portal do Contador</p>
                            <p className="text-center max-w-[25rem]">onde você terá acesso às melhores ferramentas e recursos para aprimorar sua jornada profissional.</p>
                        </div>
                    </div>


                </div>

                {/**form */}
                <div className="w-[50%] mt-24 h-[100%] max-md:w-full">
                    <div className='flex flex-col justify-center items-center' >
                        <h2 className="text-center font-semibold text-xl text-black ">Login</h2>
                        <form className='flex flex-col items-center my-16 gap-6'>

                            <InputComponent
                                placeholder={'Usuário'}
                                value={user}
                                Onchange={(e) => setUser(e.target.value)}
                                type='text'
                            />
                            <div className='relative'>

                                <InputComponent
                                    placeholder={'Senha'}
                                    value={password}
                                    Onchange={(e) => setPassword(e.target.value)}
                                    type={visible ? 'text' : 'password'}

                                />
                                <div className='cursor-pointer absolute right-4 bottom-2'>
                                    {visible ?
                                        <Eye
                                            size={20}
                                            color='grey'
                                            onClick={() => setVisible(false)}

                                        /> :

                                        <EyeOff
                                            size={20}
                                            color='grey'
                                            onClick={() => setVisible(true)}
                                        />

                                    }

                                </div>

                            </div>

                            <button className='text-white bg-orange-500 rounded-md py-2 px-6 hover:bg-orange-300 duration-200 w-[20rem]'
                                onClick={RunLogin}
                            >
                                Entrar
                            </button>


                            <Link to={'/recoverPassword'}>
                                <p className='text-sm cursor-pointer hover:text-orange-500'>Esqueci minha senha</p>
                            </Link>

                        </form>
                    </div>


                </div>
            </div>


        </div>
    )
}