import React, { useState, useEffect, useContext } from 'react';
import xmljs from 'xml-js';
import { Document, PDFViewer, StyleSheet, View, Text, Page, Font, Image } from "@react-pdf/renderer";
import { format } from 'date-fns';
import Barcode from 'react-barcode';
import JsBarcode from 'jsbarcode';
import { context } from '../../contexts';
// Importe o PDFViewer
// import { Container } from './styles';

function NoteCancelled({ xml, close }) {

    const [data, setData] = useState(null)
    const [dataNote, setDataNote] = useState(null)
    const { xmlEvento, setXmlEvento } = useContext(context)

    useEffect(() => {
        const result = xmljs.xml2js(xmlEvento, { compact: true });

        setData(result)
        const resultXmlNote = xmljs.xml2js(xml, { compact: true });
        setDataNote(resultXmlNote)

    }, [xmlEvento]);


    const evento = data?.procEventoNFe?.evento?.infEvento ?? {}
    const cOrgao = evento?.cOrgao?._text ?? ''
    const tpAmb = evento?.tpAmb?._text ?? ''
    const CNPJ = evento?.CNPJ?._text ?? ''
    const chNFe = evento?.chNFe?._text ?? ''
    const dhEvento = evento?.dhEvento?._text ?? ''
    const tpEvento = evento?.tpEvento?._text ?? ''
    const nSeqEvento = evento?.nSeqEvento?._text ?? ''
    const verEvento = evento?.verEvento?._text ?? ''
    const descEvento = evento?.detEvento?.descEvento?._text ?? ''
    const nProt = evento?.detEvento?.nProt?._text ?? ''
    const xJust = evento?.detEvento?.xJust?._text ?? ''
    const dhRegEvento = evento.retEvento?.dhRegEvento?._text ?? ''
    const infoEvento = data?.procEventoNFe?.retEvento?.infEvento ?? {}

    const nfce = dataNote?.nfeProc?.NFe?.infNFe ?? {}
    const fat = nfce?.cobr?.fat ?? {}
    const destIe = nfce?.dest?.IE ?? ''
    const dest = nfce?.dest?.CNPJ?._text ?? ''
    const emailDest = nfce?.dest?.email?._text ?? ''
    const enderDest = nfce?.dest?.enderDest ?? {}
    const xNomeDest = nfce?.dest?.xNome?._text ?? ''
    const emit = nfce?.emit ?? {}
    const CNPJemit = emit?.CNPJ?._text ?? ''
    const CRT = emit?.CRT?._text ?? ''
    const ie = emit?.IE?._text ?? ''
    const enderEmit = emit?.enderEmit ?? {}
    const xFant = emit?.xFant?._text ?? ''
    const xNome = emit?.xNome?._text ?? ''
    const chaveAcesso = dataNote?.nfeProc?.protNFe?.infProt?.chNFe?._text ?? ''

    const date = new Date()
    // colocar a data no formato mes e ano
    const FormatedMounth = (value) => {
        if (value) {
            return format(value, 'MM/yy')
        }
        return 0;

    }
    const FormatedDate = (value) => {
        if (value) {
            return format(value, 'dd/MM/yyyy  HH:mm:ss')
        }
        return 0;

    }


    // Importe a fonte desejada para o PDF
    Font.register({
        family: 'Roboto',
        src: require('../../assets/arial.ttf'),
    });

    const styles = StyleSheet.create({
        page: {
            padding: 20,
            fontFamily: 'Arial',
            fontSize: 12,
        },
        container: {
            marginRight: 10,
            marginLeft: 10,
        },
        header: {
            marginTop: 10,
            borderWidth: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            fontSize: 16,
            marginBottom: 10,
            fontWeight: 'bold',
        },
        textHeader: {
            fontSize: 10,
        },
        section: {
            marginTop: 5,
            marginBottom: 10,
        },
        tableRow: {
            borderWidth: 1,
            padding: 5,
        },
        tableColumn: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableCell: {
            borderWidth: 1,
            padding: 2,
            flex: 1,
            flexDirection: 'column',
        },
        tableCellHeader: {
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: 7
        },
        text: {
            fontSize: 9
        }

    });


    const [barCode, setBarCode] = useState('')
    // For Barcode
    useEffect(() => {

        try {
            let canvas = document.createElement('canvas')
            JsBarcode(canvas, chaveAcesso)
            const code = canvas.toDataURL()

            setBarCode(code)
        }
        catch (error) {
            console.log(error)
        }

    }, [chaveAcesso])

    return (
        <div >

            <button className=' bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[15rem] min-h-7
                    '
                onClick={close}
            >
                Fechar
            </button>

            <div className="my-2 w-full overflow-auto">
                <PDFViewer className="w-[80vw] h-[80vh]">

                    <Document>

                        <Page size="A4" style="border: 1px solid black">
                            <View style={styles.container}>
                                <View style={styles.header}>
                                    <Text style={styles.title}>CANCELAMENTO DE NF-e</Text>
                                    <Text style={styles.textHeader}>Não possui valor fiscal. Simples representação do evento indicado abaixo.</Text>
                                    <Text style={styles.textHeader}>CONSULTE A AUTENTICIDADE NO SITE DA SEFAZ AUTORIZADORA</Text>
                                </View>
                                <View style={styles.section}>
                                    <Text style={styles.textHeader}>NOTA FISCAL ELETRÔNICA - NF-e</Text>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColumn}>
                                            <View>
                                                <View style={styles.tableCell}>
                                                    <Text style={styles.tableCellHeader}>MODELO </Text>
                                                    <Text style={styles.text}>{nfce && nfce?.ide?.mod?._text}</Text>
                                                </View>
                                            </View>
                                            <View>
                                                <View style={styles.tableCell}>
                                                    <Text style={styles.tableCellHeader}>SÉRIE </Text>
                                                    <Text style={styles.text}>{nfce && nfce?.ide?.serie?._text}</Text>
                                                </View>
                                            </View>
                                            <View>
                                                <View style={styles.tableCell}>
                                                    <Text style={styles.tableCellHeader}>NÚMERO </Text>
                                                    <Text style={styles.text}>{nfce && nfce?.cobr?.fat?.nFat?._text}</Text>
                                                </View>
                                            </View>
                                            <View>
                                                <View style={styles.tableCell}>
                                                    <Text style={styles.tableCellHeader}>MÊS/ANO DE EMISSÃO:  </Text>
                                                    <Text style={styles.text}>{nfce && FormatedMounth(nfce?.ide?.dhEmi?._text)}</Text>
                                                </View>
                                            </View>


                                            <View style={styles.tableCell}>
                                                {
                                                    barCode !== '' ?
                                                        <Image
                                                            src={barCode}
                                                            style={{
                                                                width: 300

                                                                , objectFit: 'cover'
                                                            }}
                                                        /> : ''
                                                }



                                            </View>







                                        </View>

                                        <View style={styles.tableColumn}>
                                            <View style={styles.tableCell}>
                                                <Text style={styles.tableCellHeader}>CHAVE DE ACESSO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{chaveAcesso}</Text>
                                            </View>

                                        </View>
                                    </View>
                                </View>
                                <View style={styles.section}>
                                    <Text style={styles.textHeader}>CANCELAMENTO DE NF-e</Text>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColumn}>
                                            <View style={styles.tableCell}>
                                                <Text style={styles.tableCellHeader}>ORGÃO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{cOrgao}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                <Text style={styles.tableCellHeader}>AMBIENTE </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{tpAmb === '1' ? 'PRODUÇÃO' : 'HOMOLOGAÇÃO'}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                <Text style={styles.tableCellHeader}>DATA E HORÁRIO DO EVENTO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{dhEvento && FormatedDate(dhEvento)}</Text>
                                            </View>





                                        </View>
                                        <View style={styles.tableColumn}>


                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}> EVENTO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{tpEvento && tpEvento}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>DESCRIÇÃO DO EVENTO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{descEvento && descEvento}</Text>
                                            </View>

                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>SEQUÊNCIA DO EVENTO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{nSeqEvento && nSeqEvento}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                <Text style={styles.tableCellHeader}>VERSÃO DO EVENTO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{verEvento && verEvento}</Text>
                                            </View>

                                        </View>
                                        <View style={styles.tableColumn}>


                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}> STATUS </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{infoEvento && infoEvento?.cStat?._text} - {infoEvento?.xMotivo?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>PROTOCOLO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{nProt && nProt}</Text>
                                            </View>

                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>DATA E HORÁRIO DO REGISTRO </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{infoEvento && FormatedDate(infoEvento?.dhRegEvento?._text)}</Text>
                                            </View>


                                        </View>
                                    </View>
                                </View>




                                <View style={styles.section}>
                                    <Text style={styles.textHeader}>EMITENTE</Text>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColumn}>

                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>NOME / RAZÃO SOCIAL </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{xNome}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>CNPJ / CPF </Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{CNPJ}</Text>
                                            </View>

                                        </View>
                                        <View style={styles.tableColumn}>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>ENDEREÇO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderEmit?.xLgr?._text} {enderEmit?.nro?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>BAIRRO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderEmit?.xBairro?._text}</Text>
                                            </View>

                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>CEP</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderEmit?.CEP?._text}</Text>
                                            </View>


                                        </View>
                                        <View style={styles.tableColumn}>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>MUNICÍPIO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderEmit?.xMun?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>ESTADO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderEmit?.UF?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>FONE / FAX</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderEmit?.fone?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>INSCRIÇÃO ESTADUAL</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{ie}</Text>
                                            </View>

                                        </View>

                                    </View>
                                </View>
                                <View style={styles.section}>
                                    <Text style={styles.textHeader}>DESTINATÁRIO / REMETENTE</Text>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColumn}>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>NOME / RAZÃO SOCIAL</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{xNomeDest}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>CNPJ / CPF</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{dest}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableColumn}>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>ENDEREÇO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderDest?.xLgr?._text} {enderDest?.nro?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>BAIRRO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderDest?.xBairro?._text}</Text>
                                            </View>

                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>CEP</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderDest?.CEP?._text}</Text>
                                            </View>


                                        </View>
                                        <View style={styles.tableColumn}>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>MUNICÍPIO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderDest?.xMun?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>ESTADO</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderDest?.UF?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>FONE / FAX</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{enderDest?.fone?._text}</Text>
                                            </View>
                                            <View style={styles.tableCell}>

                                                <Text style={styles.tableCellHeader}>INSCRIÇÃO ESTADUAL</Text>
                                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{destIe && destIe?._text}</Text>
                                            </View>

                                        </View>
                                    </View>
                                </View>
                                <View style={styles.section}>
                                    <Text style={styles.textHeader}>Cancelamento</Text>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColumn}>
                                            <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>{evento?.detEvento?.xJust?._text}</Text>
                                        </View>

                                    </View>
                                </View>
                                <Text style={[styles.text, { fontSize: 9, fontWeight: 'extrabold' }]}>DATA E HORA DA IMPRESSÃO: {FormatedDate(date)}</Text>
                            </View>
                        </Page>
                    </Document>

                </PDFViewer>
            </div>


        </div>
    )
}

export default NoteCancelled;