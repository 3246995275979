function verificarTipoNotaFiscal(chaveAcesso) {
    // Verifica se a chave de acesso tem 44 dígitos
    if (chaveAcesso.length !== 44) {
        throw new Error('Chave de acesso inválida. Deve conter 44 dígitos.');
    }

    // Extrai o modelo da chave de acesso (dígitos 23 e 24)
    const modeloNota = chaveAcesso.substring(20, 22);

    if (modeloNota === '55') {
        return 'NF-e';
    } else if (modeloNota === '65') {
        return 'NFC-e';
    } else {
        return 'Modelo de nota fiscal desconhecido';
    }
}

export default verificarTipoNotaFiscal;