import { Modal } from "@mui/material";
import { AtSign, Contact, HomeIcon, User, ScrollText, Key, LogOut, Menu, X } from "lucide-react";
import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { context } from "../../contexts";
import AlterPasword from "./alterPassword";
export default function Header() {
    const { open, setOpen } = useContext(context)
    const [openPassword, setOpenPassword] = useState(false)
    const [suport, setSuport] = useState(false)

    const menuList = [
        {
            id: 1,
            option: 'Principal',
            route: '/home'

        },
        {
            id: 2,
            option: 'Clientes',
            route: '/clients'
        },
        {
            id: 3,
            option: 'Vínculos',
            route: '/boundClients'
        },
        {
            id: 4,
            option: 'Usuários',
            route: '/users'
        }, {
            id: 5,
            option: 'Notas Fiscais',
            route: '/notes'
        },
        {
            id: 6,
            option: 'Mudar Senha',
            route: () => setOpenPassword(true)
        },
        {
            id: 7,
            option: 'Sair',
            route: '/'
        }
    ]
    const navigate = useNavigate()
    // quando o usuario for contador ele tem acesso a esses itens do menu
    const menuListCounter = [
        {
            id: 1,
            option: 'Principal',
            route: '/home'

        },
        {
            id: 2,
            option: 'Notas Fiscais',
            route: '/notes'
        },
        {
            id: 3,
            option: 'Mudar Senha',
            route: () => setOpenPassword(true)
        },
        {
            id: 4,
            option: 'Sair',
            route: () => Logout()

        },

    ]

    useEffect(() => {
        async function getDataUser() {
            const userDataString = localStorage.getItem('@user');

            const userdata = JSON.parse(userDataString);
            if (userdata.tipo === 'S') {
                setSuport(true)
            } else {
                setSuport(false)
            }


        }

        getDataUser();
    }, []);


    const location = useLocation()
    const pathname = location.pathname;


    // quando o usuario clicar em sair ele remove os dados do usuario do localstorage
    function Logout() {
        localStorage.removeItem('@user')
        navigate('/')
    }


    return (
        <div >
            <div className='max-md:block  hidden'>


                {
                    open ?
                        '' :
                        <div className="w-[100vw] bg-orange-400 h-12 fixed mb-20 z-50">


                            <Menu size={24}
                                onClick={() => setOpen(true)}

                                className='absolute left-2 cursor-pointer top-2 text-white '
                            />
                        </div>
                }
            </div>
            <div className={`bg-orange-500 h-[100%]  text-white justify-center flex px-3 relative
        transition ease-in-out max-md:fixed max-md:w-[100vw] max-md:z-50 max-md max-md:${open ? 'block' : 'hidden'}
       ${open ? 'min-w-[10rem]' : ''}
        
        `}>
                {
                    open ?
                        <X
                            size={24}
                            onClick={() => setOpen(false)}
                            className='absolute left-2 cursor-pointer top-2'
                        /> :

                        <Menu size={24}
                            onClick={() => setOpen(true)}
                            className='absolute left-2 cursor-pointer top-2'
                        />
                }

                <div>
                    <ul className="flex flex-col gap-4 mt-20 ">
                        {
                            suport === true ?
                                menuList.map((item) => (
                                    <div>
                                        <div className={`flex items-center gap-2
                            hover:bg-orange-200 cursor-pointer rounded-md px-2  h-8 

                            ${pathname === item.route ? 'bg-orange-200 ' : ''}
                            `}>


                                            <div className="flex items-center gap-2"
                                                onClick={item.option === 'Mudar Senha' ?
                                                    () => setOpenPassword(true) : item.option === 'Sair' ?
                                                        () => Logout() : () => navigate(item.route)
                                                }
                                            >


                                                {
                                                    item.option === 'Principal' ?
                                                        <HomeIcon size={20} /> : item.option === 'Clientes' ?
                                                            <Contact size={20} /> : item.option === 'Vínculos' ?
                                                                <AtSign size={20} /> : item.option === 'Usuários' ?
                                                                    <User size={20} /> : item.option === 'Notas Fiscais' ?
                                                                        <ScrollText size={20} /> : item.option === 'Mudar Senha' ?
                                                                            <Key size={20} /> :
                                                                            <LogOut size={20} />
                                                }
                                                <li className={`${open === false ? 'hidden' : 'visible'} transition ease-in-out duration-500 text-sm`} >{item.option}</li>
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                menuListCounter.map((item) => (
                                    <div>
                                        <div className={`flex items-center gap-2
                            hover:bg-orange-200 cursor-pointer rounded-md px-2  h-8 

                            ${pathname === item.route ? 'bg-orange-200 ' : ''}
                            `}>


                                            <div className="flex items-center gap-2"
                                                onClick={item.option === 'Mudar Senha' ?
                                                    () => setOpenPassword(true) : item.option === 'Sair' ?
                                                        () => Logout() : () => navigate(item.route)}
                                            >


                                                {
                                                    item.option === 'Principal' ?
                                                        <HomeIcon size={20} /> : item.option === 'Clientes' ?
                                                            <Contact size={20} /> : item.option === 'Vínculos' ?
                                                                <AtSign size={20} /> : item.option === 'Usuários' ?
                                                                    <User size={20} /> : item.option === 'Notas Fiscais' ?
                                                                        <ScrollText size={20} /> : item.option === 'Mudar Senha' ?
                                                                            <Key size={20} /> :
                                                                            <LogOut size={20} />
                                                }
                                                <li className={`${open === false ? 'hidden' : 'visible'} transition ease-in-out duration-500 text-sm`} >{item.option}</li>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }

                    </ul>

                </div>


            </div>

            <Modal open={openPassword} className='flex justify-center items-center'>
                <AlterPasword setOpen={setOpenPassword} />
            </Modal>

        </div>
    )
}



