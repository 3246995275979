import { Drawer } from "@mui/material"
import Header from "../../components/header/Header"
import Card from "@mui/material/Card"
import { SquareUser, CopyCheck, Ban, ListChecks } from 'lucide-react';
import DatePicker from "react-datepicker";
import { useState, useEffect, useContext } from "react";
import Charts from './Charts';
import { format } from 'date-fns'
import axios from "axios";
import api from "../../services/api";
import { toast } from 'react-toastify';
import CircularProgress from "@mui/material/CircularProgress";
import { context } from "../../contexts";
export default function Home() {




    const [loading, setLoading] = useState(false)

    const [alterDate, setAlterDate] = useState(false)
    const [userData, setUserData] = useState(null)


    const [user, setUser] = useState('')
    const { dashboardData, setDashboardData, date, setDate, getDashboard, getClients, clients, setClients } = useContext(context)






    // função para buscar os clientes vinculados a quem esta logado


    async function getFunctions() {
        setLoading(true);
        try {
            await Promise.all([getDashboard(), getClients()]);
        } catch (error) {
            console.error('Error fetching functions:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        getFunctions()


    }, [])
    useEffect(() => {
        const data = localStorage.getItem('@user')
        if (data) {
            const userData = JSON.parse(data)
            setUser(userData)
            setUserData(userData) // Definir userData quando há dados no localStorage
        }
    }, [])


    /**
    useEffect(() => {
        if (userData) {
            setLoading(true)// Verificar se userData é válido antes de chamar as funções
            Promise.all([getDashboard(), getClients()]) // Executa ambas as funções em paralelo
                .then(() => setLoading(false)) // Define loading como false após o término de ambas as funções
                .catch(() => setLoading(false)); // Em caso de erro, também define loading como false

        }
    }, [userData]) // Executar quando userData mudar

    useEffect(() => {
        setLoading(true)
        Promise.all([getDashboard(), getClients()]) // Executa ambas as funções em paralelo
            .then(() => setLoading(false)) // Define loading como false após o término de ambas as funções
            .catch(() => setLoading(false)); // Em caso de erro, também define loading como false

    }, [alterDate]) // Executar quando a data mudar

 */

    let nfceCanceladas = [];

    let nfce = [];

    if (dashboardData.NFeNFCeCanceladas) {
        nfceCanceladas = dashboardData.NFeNFCeCanceladas.map((item) => ({
            modelo: item.MODELO,
            total: item.TOTAL
        }));
    }
    if (dashboardData.NFeNFCe) {
        nfce = dashboardData.NFeNFCe.map((item) => ({
            modelo: item.MODELO,
            total: item.TOTAL
        }));
    }



    function calcularPorcentagemNfce(dados) {
        // Primeiro, somamos todos os totais para calcular o total geral
        const totalGeral = dados.reduce((total, item) => total + item.total, 0);

        // Verificar se o total geral é diferente de zero para evitar divisão por zero
        if (totalGeral !== 0) {
            // Em seguida, mapeamos os dados para calcular a porcentagem para cada modelo
            const dadosComPorcentagem = dados.map(item => ({
                ...item,
                porcentagem: (item.total / totalGeral) * 100
            }));

            return dadosComPorcentagem;
        } else {
            // Se o total geral for zero, retornamos os dados originais sem calcular a porcentagem
            return dados;
        }
    }


    const nfcePorcentagem = calcularPorcentagemNfce(nfce)
    const canceladasPorcentagem = calcularPorcentagemNfce(nfceCanceladas)






    return (
        <div className="flex h-[100vh] w-[100vw] relative ">

            <Header />
            <div className='mx-8 my-8 max-md:mt-14'>
                <p className="font-semibold">Seja bem vindo ao Portal do Contador</p>
                {/**filter date */}

                <div className="my-4 mx-4 flex items-center ">

                    <div>
                        <p className=" text-sm ">Mês - Ano </p>
                        <DatePicker selected={date} onChange={(date) => {
                            setDate(date)
                            setAlterDate(!alterDate)
                        }}
                            dateFormat="MM/yyyy" // Define o formato para mostrar apenas o mês e o ano
                            showMonthYearPicker // Mostra apenas o seletor de mês e ano
                            className="border-2 outline-none border-gray-200 w-[6rem] rounded-md"
                        />
                    </div>

                    <button className='mx-12 bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[12rem] min-h-7 h-7
                    '
                        onClick={getFunctions}
                    >
                        Gerar Dashboard
                    </button>
                </div>

                {
                    loading ?
                        <div className='absolute left-[50%] top-[50%]'>
                            <CircularProgress color='warning' />
                        </div> :
                        <div className="flex items-center gap-6 mt-4  max-lg:grid grid-cols-2 max-lg:grid-cols-1 ">

                            <Card className="flex px-4 py-6 min-w-60 flex-col">
                                <div className="flex justify-between">
                                    <div>
                                        <p className="font-semibold">Emitentes</p>
                                        <p className="font-semibold text-gray-500">{dashboardData.Emitentes}</p>
                                    </div>
                                    <div>
                                        <SquareUser size={30} className='text-blue-500' />
                                    </div>
                                </div>
                            </Card>

                            <Card className="flex px-4 py-6 min-w-60 flex-col">
                                <div className="flex justify-between">
                                    <div>
                                        <p className="font-semibold">Notas Emitidas</p>
                                        <p className="font-semibold text-gray-500">{dashboardData.NotasEmitidas}</p>
                                    </div>
                                    <div>
                                        <CopyCheck size={30} className="text-green-500" />
                                    </div>
                                </div>
                            </Card>

                            <Card className="flex px-4 py-6 min-w-60 flex-col">
                                <div className="flex justify-between">
                                    <div>
                                        <p className="font-semibold">Canceladas</p>
                                        <p className="font-semibold text-gray-500">{dashboardData.Canceladas}</p>
                                    </div>
                                    <div>
                                        <Ban size={30} className="text-red-500" />
                                    </div>
                                </div>
                            </Card>

                            <Card className="flex px-4 py-6 min-w-60 flex-col">
                                <div className="flex justify-between">
                                    <div>
                                        <p className="font-semibold">Autorizadas</p>
                                        <p className="font-semibold text-gray-500">{dashboardData.Autorizadas}</p>
                                    </div>
                                    <div>
                                        <ListChecks size={30} className='text-gray-600' />
                                    </div>
                                </div>
                            </Card>



                        </div>
                }

                {
                    /**texts */


                }

                {
                    loading ? '' :

                        <div className=" flex justify-around py-6 gap-4  max-lg:grid grid-cols-2 max-sm:grid-cols-1">
                            <Card >
                                <div className="min-w-[15rem] max-w-[20rem] h-60">


                                    <div className="bg-gray-200 px-2 text-sm  text-center">
                                        <p>Documentos emitidos | NFe {nfcePorcentagem[0]?.porcentagem.toFixed(2)}% NFCe {nfcePorcentagem[1]?.porcentagem.toFixed(2)}%</p>

                                    </div>
                                    <div className="  mt-2 flex items-center justify-center">
                                        <Charts data={nfce} type='nfce' />
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className="min-w-[15rem] max-w-[20rem] h-60">
                                    <div className="bg-gray-200 px-2 text-sm  text-center">
                                        <p>Documentos Cancelados | NFe {canceladasPorcentagem[0]?.porcentagem.toFixed(2)}% NFCe {canceladasPorcentagem[1]?.porcentagem.toFixed(2)}%</p>
                                    </div>
                                    <div className="  mt-2 flex items-center justify-center">
                                        <Charts data={nfceCanceladas} type='canceladas' />
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className="min-w-[15rem] max-w-[30rem] h-[100%]">
                                    <div className="bg-gray-200 px-2 text-sm  text-center flex-1">
                                        <p>Empresas que estou vinculado</p>
                                    </div>
                                    <div className=" min-w-[20rem] h-60 overflow-auto mt-2 my-4 gap-4">
                                        {clients.map((item) => (
                                            <p className="text-sm  hover:bg-gray-400 ">{item.NOME} - {item.CNPJ}</p>
                                        ))}
                                        {
                                            clients.length === 0 && loading === false ?
                                                <p className="text-sm text-center mt-6">Nenhum cliente vinculado</p> : ''
                                        }
                                    </div>
                                </div>
                            </Card>
                        </div>



                }
            </div>

        </div>
    )
}