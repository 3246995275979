import Login from "./pages/Login";
import './index.css'
import { BrowserRouter } from "react-router-dom";
import Routers from "./router/routers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Font } from "@react-pdf/renderer";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ContextAuth from "./contexts";
import "react-datepicker/dist/react-datepicker.css";

import "primereact/resources/themes/lara-light-cyan/theme.css";



function App() {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <BrowserRouter >
        <ContextAuth>


          <ToastContainer />

          <Routers />


        </ContextAuth>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
