import { Card, Checkbox, } from "@mui/material"
import { X, SquareUser, SearchIcon, Lock, Eye, EyeOff } from "lucide-react"
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import CircularProgress from "@mui/material/CircularProgress";
export default function AlterPasword({ setOpen,

}) {


    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const errorMessage = 'A senhas não coincidem!'
    const [error, setError] = useState(false)



    useEffect(() => {
        async function getDataUser() {

            const dataUser = localStorage.getItem('@user')
            const data = JSON.parse(dataUser)
            if (data) {
                setUser(data.usuario)
            }


        }
        getDataUser()
    }, [])


    async function UpdatePassword() {
        if (password !== '' && newPassword !== '' && confirmPassword !== '') {
            if (newPassword !== confirmPassword) {
                setError(true)
            } else {

                setError(false)
                setLoading(true)
                const data = {
                    senhaatual: password,
                    novasenha: newPassword
                }
                await axios.put(`${api}usuario/mudarsenha/${user}`, data)
                    .then((res) => {

                        setLoading(false)
                        if (res.data.message === 'Senha atual incorreta.') {
                            toast.error('Senha senha atual incorreta')
                        } else {
                            toast.success('Senha alterada com sucesso')
                            setOpen(false)
                        }

                    })
                    .catch((error) => {
                        toast.error('Erro ao alterar senha')
                        setLoading(false)
                    })
            }
        } else {
            toast.warn('Preencha os campos vazios')
        }


    }
    return (
        <div className="w-[35rem] h-[25rem] bg-white  mx-4 mt-3 ">
            <div className=" mx-4 mt-3 ">

                <div className="flex justify-between">
                    <Card className="p-1">
                        <Lock
                            className="text-gray-700 cursor-pointer"
                        />
                    </Card>

                    <X
                        className="text-gray-700 cursor-pointer"
                        onClick={() => setOpen(false)}
                    />
                </div>
                <div className="my-4">
                    <h2 className="font-semibold ">Alterar Senha</h2>
                    <span className='text-sm text-gray-400'>Preencha todos os campos</span>
                </div>

            </div>

            <div className="mx-4 flex items-center justify-center">
                <form className="  gap-2 grid grid-cols-2">


                    <div className="flex-col gap-1 ">
                        <p className="font-semibold text-sm">Usuário</p>
                        <input
                            type='text'
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                            className='outline-none border-2 px-4 py-1 rounded-md'


                        />
                    </div>
                    <div className="flex-col gap-1 relative ">
                        <p className="font-semibold text-sm">Senha atual</p>
                        <input
                            type={visible ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='outline-none border-2 px-4 py-1 rounded-md'


                        />
                        {
                            visible ?
                                <Eye color='grey' size={20}
                                    className='absolute top-[50%] right-4 cursor-pointer'
                                    onClick={() => setVisible(false)}
                                /> :

                                <EyeOff
                                    color='grey' size={20}
                                    className='absolute top-[50%] right-4 cursor-pointer'
                                    onClick={() => setVisible(true)}
                                />
                        }

                    </div>
                    <div className="flex-col gap-1 relative ">
                        <p className="font-semibold text-sm">Nova senha</p>
                        <input
                            type={visible ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={`outline-none border-2 px-4 py-1 rounded-md ${error ? 'border-red-500' : ''}`}


                        />

                        {
                            visible ?
                                <Eye color='grey' size={20}
                                    className='absolute top-[50%] right-4 cursor-pointer'
                                    onClick={() => setVisible(false)}
                                /> :

                                <EyeOff
                                    color='grey' size={20}
                                    className='absolute top-[50%] right-4 cursor-pointer'
                                    onClick={() => setVisible(true)}
                                />
                        }
                    </div>
                    <div className="flex-col gap-1 relative ">
                        <p className="font-semibold text-sm">Confirme a nova senha</p>
                        <input
                            type={visible ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={`outline-none border-2 px-4 py-1 rounded-md ${error ? 'border-red-500' : ''}`}


                        />
                        {
                            visible ?
                                <Eye color='grey' size={20}
                                    className='absolute top-[50%] right-4 cursor-pointer'
                                    onClick={() => setVisible(false)}
                                /> :

                                <EyeOff
                                    color='grey' size={20}
                                    className='absolute top-[50%] right-4 cursor-pointer'
                                    onClick={() => setVisible(true)}
                                />
                        }
                    </div>
                    <div className="flex items-center">
                        {
                            error ?
                                <span className=" text-center text-sm text-red-500">{errorMessage}</span> : ''
                        }
                    </div>

                </form>
            </div>

            <div className="flex gap-4 mt-10 items-center justify-center">
                <button
                    className="bg-white text-black min-w-[12rem] py-2 rounded-md border-2 
                    hover:text-red-500 duration-300
                    "
                    onClick={() => setOpen(false)}
                >

                    Cancelar
                </button>
                <button
                    type='submit'
                    className="bg-orange-500 text-white min-w-[12rem] py-2 rounded-md 
                    hover:bg-orange-300 duration-300
                    "
                    onClick={UpdatePassword}
                >
                    {
                        loading ?
                            <CircularProgress size={20} color='inherit' />

                            :
                            ' Confirmar'

                    }

                </button>
            </div>
        </div>

    )
}