export default function List({ data, type, selected, setSelected }) {



    return (

        <div className={`w-[18rem] my-2 cursor-pointer bg-gray-100 rounded-sm hover:bg-gray-300 p-1 
        ${selected === data.CODUSUARIO && type === 'user' ? 'bg-gray-500 text-white' : selected === data.CNPJ && type === 'clients' ? 'bg-gray-500 text-white' : ''}
         `} onClick={
                type === 'bonds' ?
                    () => { } :
                    type === 'user' ?

                        () => setSelected(data.CODUSUARIO) :
                        () => setSelected(data.CNPJ)

            }>


            {
                type === 'user' ?
                    <p className="text-sm">{data.CODUSUARIO}</p> :
                    <p p className="text-sm">{data.NOME}</p>
            }

        </div >

    )
}