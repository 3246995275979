export default function FormatCnpj(value) {
    // Remove caracteres não numéricos
    const cleaned = ('' + value).replace(/\D/g, '');

    // Formatação do CNPJ
    const cnpj = cleaned.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');

    return cnpj;
}

export function removeCnpjFormat(cnpj) {
    // Remove caracteres não numéricos
    return cnpj.replace(/\D/g, '');
}
