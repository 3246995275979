import Header from "../../components/header/Header";
import { Document, PDFViewer, StyleSheet, View, Text, Page } from "@react-pdf/renderer";
import FormatDate from "../../helpers/FormatDate";
import { Link } from "react-router-dom";
import { useState } from "react";
export default function NotePrint() {

    const date = new Date()
    //enumera as paginas
    const [currentPage, setCurrentPage] = useState(1)
    //documento pf para test
    const styles = StyleSheet.create({
        page: {
            backgroundColor: 'white'
        },
        header: {
            margin: 10,
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',

        },
        textHeader: {
            fontSize: 8,
            fontWeight: 'bold'
        },
        title: {
            textAlign: 'center',
            fontSize: 14
        },
        table: {
            marginTop: 30,
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            marginLeft: 10,
            marginRight: 20
        },
        tableRow: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        tableColHeader: {
            width: '25%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            alignItems: 'center',
            justifyContent: 'center',
        },
        tableCol: {
            width: '25%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,

            borderTopWidth: 0,
            alignItems: 'flex-start', // Ajuste para alinhamento à esquerda
            justifyContent: 'center',
            paddingLeft: 5, // Adiciona padding à esquerda para espaçamento
        },
        text: {
            fontSize: 8,
            fontWeight: 'normal',
            textAlign: 'center',
            marginTop: 10
        }
    });
    // Create styles
    // Create Document Component
    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page} wrap>

                <View style={styles.header}>
                    <Text style={styles.text}></Text>
                    <Text style={styles.text}>RELAÇÃO DE NOTAS EMITIDAS</Text>
                    <View>
                        <Text style={styles.textHeader}>eRepositórioXML</Text>
                        <Text style={styles.textHeader}>Emissão: {FormatDate(date)}</Text>

                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={[styles.textHeader, { padding: 10, marginLeft: 10 }]} render={({ pageNumber, totalPages }) => (
                        `Página: ${pageNumber}/${totalPages}`
                    )} />
                    <View style={styles.table}>

                        <View style={styles.tableRow}>
                            <View style={[styles.tableColHeader, { width: '15%' }]}>
                                <Text style={styles.text}>NÚMERO</Text>
                            </View>
                            <View style={[styles.tableColHeader, { width: '10%' }]}>
                                <Text style={styles.text}>MODELO</Text>
                            </View>
                            <View style={[styles.tableColHeader, { width: '10%' }]}>
                                <Text style={styles.text}>SÉRIE</Text>
                            </View>
                            <View style={[styles.tableColHeader, { width: '40%' }]}>
                                <Text style={styles.text}>CHAVE DE ACESSO</Text>
                            </View>
                            <View style={[styles.tableColHeader, { width: '25%' }]}>
                                <Text style={styles.text}>CPF/CNPJ</Text>
                            </View>
                            <View style={[styles.tableColHeader, { width: '10%' }]}>
                                <Text style={styles.text}>TOTAL - R$</Text>
                            </View>
                        </View>

                        {
                            /** {
                            filteredList.map((item) => (
                                <View style={styles.tableRow}>

                                    <Text style={[styles.text, { width: '15%' }]}>{item.CODIGO}</Text>
                                    <Text style={[styles.text, { width: '35%' }]}>{item.NOME}</Text>
                                    <Text style={[styles.text, { width: '25%' }]}>{item.CPF}</Text>
                                    <Text style={[styles.text, { width: '25%' }]}>{item.TELEFONE1}   {item.CELULAR}</Text>

                                </View>
                            ))
                        } */
                        }






                    </View>

                </View>
            </Page>
        </Document>
    )


    return (
        <div className="flex h-full w-full overflow-x-hidden ">
            <Header />

            <div className='mx-8 my-8 max-md:mt-14'>
                <Link to={'/notes'}>
                    <button className=' bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[15rem] min-h-7
                    '

                    >
                        Voltar
                    </button>
                </Link>


                <div className="my-4 w-full overflow-auto">
                    <PDFViewer
                        className="w-[80vw] h-[80vh]"

                    >
                        <MyDocument />
                    </PDFViewer>
                </div>
            </div>

        </div>
    )
}