import React, { useContext, useState, useEffect, } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import xmljs from 'xml-js';
import { context } from "../contexts";
import { format } from 'date-fns'

import QRCode from "qrcode";
import FormatCurrency from "../helpers/FormatCurrency";



const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 10,
        width: '100%',

    },
    section: {
        marginBottom: 10,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#000',
    },
    headerText: {
        fontSize: 8,
        textAlign: 'center',

    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        paddingBottom: 10
    },
    titleDanfe: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: 10,
        textAlign: 'center',
        marginBottom: 5,
    },
    text: {
        fontSize: 7,
        marginBottom: 2,
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        paddingBottom: 5,
        marginBottom: 5,
    },
    textHeaderCol: {
        fontSize: 7,
        fontWeight: 'bold'
    },
    tableRows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: 5,
    },
    totalSection: {
        marginTop: 10,
        borderTopWidth: 1,
        borderTopColor: '#000',
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    caixa: {
        fontSize: 7,
        marginBottom: 2,
        width: 220
    }
});

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const PrintComponent = ({ close }) => {
    const { selected, keyNote, setKeyNote, tiponfce, setTipoNfce } = useContext(context);
    const [pdfData, setPdfData] = useState(null);
    const [qr64, setQr64] = useState('')


    console.log(pdfData)
    useEffect(() => {
        if (selected) {
            const result = xmljs.xml2js(selected, { compact: true });
            setPdfData(result);

        }
    }, [selected]);

    useEffect(() => {
        if (pdfData) {
            const qr = pdfData.nfeProc?.NFe?.infNFeSupl.qrCode._text;


            if (qr) {
                // Usando qrcode.react para gerar o código QR
                const qrDataUrl = QRCode.toDataURL(qr);

                qrDataUrl.then((data) => {

                    setQr64(data)
                }).catch((error) => {
                    console.error('Erro ao gerar o código QR:', error);
                });
            }
        }
    }, [pdfData]);




    if (!pdfData) {
        return <div>Loading...</div>;
    }


    const encodexml = btoa(selected)

    const ide = pdfData.nfeProc?.NFe?.infNFe?.ide ?? {};
    const emit = pdfData.nfeProc?.NFe?.infNFe?.emit ?? {};
    const det = pdfData.nfeProc?.NFe?.infNFe?.det ?? [];
    const total = pdfData.nfeProc?.NFe?.infNFe?.total?.ICMSTot ?? {};
    const imposto = det[1]?.imposto?.vTotTrib?._text ?? '';
    const serie = ide?.serie?._text ?? '';
    const caixa = pdfData.nfeProc?.NFe?.infNFe?.infAdic?.infCpl?._text ?? '';
    const resp = pdfData.nfeProc?.NFe?.infNFe?.infRespTec ?? {};
    const qrCode = pdfData.nfeProc?.NFe?.infNFeSupl?.qrCode?._text ?? '';
    const protocolo = pdfData.nfeProc?.protNFe?.infProt?.nProt?._text ?? '';


    const FormatDate = (date) => {
        return format(date, 'dd/MM/yyyy  HH:mm:ss')
    }


    console.log(pdfData)

    return (
        <div className="my-4 w-full overflow-auto">

            <button
                className=' bg-orange-500 text-white px-4 rounded-md text-sm
              hover:bg-orange-300 max-w-[15rem] min-h-7 mb-2 mx-2
              '
                onClick={close}
            >
                Fechar
            </button>

            <div className="flex flex-col items-center justify-center">



                <PDFViewer className="w-[80vw] h-[80vh]">
                    <Document>
                        <Page size={[200, 800]} style={styles.page}>
                            <View style={styles.header}>
                                <Text style={styles.headerText}>{emit.xFant._text}</Text>
                                <Text style={styles.headerText}>CNPJ: {emit.CNPJ._text} - IE {emit.IE._text}</Text>
                                <Text style={styles.headerText}>{emit.enderEmit.xLgr._text}, {emit.enderEmit.nro._text}, {emit.enderEmit.xBairro._text}</Text>
                                <Text style={styles.headerText}>{emit.enderEmit.xMun._text} - {emit.enderEmit.UF._text}, CEP: {emit.enderEmit.CEP._text}</Text>
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.titleDanfe}>DANFE NFC-e - Documento Auxiliar</Text>
                                <Text style={styles.titleDanfe}>da Nota Fiscal Eletrônica</Text>
                                {
                                    tiponfce === 'C' && <Text style={styles.titleDanfe}>DOCUMENTO CANCELADO</Text>
                                }

                            </View>
                            {
                                tiponfce === 'C' ? '' :

                                    <View>
                                        <View style={styles.tableHeader}>
                                            <Text style={styles.textHeaderCol}>CÓDIGO</Text>
                                            <Text style={styles.textHeaderCol}>DESCRIÇÃO</Text>
                                            <Text style={styles.textHeaderCol}>QTD UN</Text>
                                            <Text style={styles.textHeaderCol}>VL.UNIT</Text>
                                            <Text style={styles.textHeaderCol}>VL.TOTAL</Text>
                                        </View>

                                        {Array.isArray(det) ? det.map((item, index) => (
                                            <View key={index} style={styles.tableRows}>
                                                <Text style={styles.text}>{item.prod.cEANTrib._text}</Text>
                                                <Text style={styles.text}>{item.prod.xProd._text}</Text>
                                                <Text style={styles.text}>{item.prod.qCom._text ? parseFloat(item.prod.qCom._text).toFixed(2) : ''} {item.prod.uCom._text}</Text>
                                                <Text style={styles.text}>{item.prod.vUnCom._text ? parseFloat(item.prod.vUnCom._text).toFixed(2) : ''}</Text>
                                                <Text style={styles.text}>{item.prod.vProd._text}</Text>
                                            </View>
                                        )) : (
                                            <View style={styles.tableRows}>
                                                <Text style={styles.text}>{det.prod.cEANTrib._text}</Text>
                                                <Text style={styles.text}>{det.prod.xProd._text}</Text>
                                                <Text style={styles.text}> {det.prod.qCom._text ? parseFloat(det.prod.qCom._text).toFixed(2) : ''} {det.prod.uCom._text}</Text>
                                                <Text style={styles.text}>{det.prod.vUnCom._text ? parseFloat(det.prod.vUnCom._text).toFixed(2) : ''}</Text>
                                                <Text style={styles.text}>{det.prod.vProd._text}</Text>
                                            </View>
                                        )}
                                        <View style={[styles.totalSection, { flexDirection: 'column' }]}>


                                            <Text style={styles.text}>Valor Total dos Produtos: R$ {total.vProd._text}</Text>
                                            {
                                                total?.vDesc?._text && total?.vDesc?._text !== '0.00' &&
                                                <Text style={styles.text}> Desconto: {FormatCurrency(total?.vDesc?._text)}</Text>
                                            }
                                            {
                                                total?.vOutro?._text && total?.vOutro?._text !== '0.00' &&
                                                <Text style={styles.text}> Acréscimo: {FormatCurrency(total?.vOutro?._text)}</Text>
                                            }



                                            <Text style={styles.text}>Valor Total da NF: R$ {total.vNF._text}</Text>
                                            {
                                                total?.vDesc?._text !== '0.00' &&
                                                <Text style={styles.text}>Valor Total de Descontos: R$ {parseFloat(total?.vDesc?._text).toFixed(2)} </Text>
                                            }
                                        </View>
                                        <View style={[styles.totalSection, { borderBottomWidth: 1, padding: 5 }]}>
                                            <View>
                                                <Text style={styles.text}>Informação dos Tributos Totais Incidentes</Text>
                                                <Text style={styles.text}>(Lei Federal 12.741/2012):</Text>
                                            </View>

                                            <Text style={styles.text}>{imposto}</Text>

                                        </View>
                                        <View style={[styles.section, { padding: 5, flexWrap: 'wrap' }]}>
                                            <Text style={styles.caixa}>{caixa}</Text>
                                        </View>
                                    </View>
                            }





                            <View style={[styles.section]}>
                                <Text style={styles.text}>Número da NF: {ide.nNF._text} - Série: {serie}</Text>
                                <Text style={styles.text}>Data de Emissão: {ide && ide.dhEmi._text ? FormatDate(ide.dhEmi._text) : ''}</Text>
                            </View>
                            <View style={[styles.section, { alignItems: 'center' }]}>
                                <Text style={styles.text}>Consulte sua chave de acesso em:</Text>
                                <Text style={styles.text}>www.sefaz.al.gob.br/nfce/consulta</Text>
                                <Text style={[styles.text, { fontWeight: 'extrabold' }]}>CHAVE DE ACESSO</Text>
                                <Text style={[styles.text, { fontWeight: 'extrabold' }]}>{keyNote}</Text>
                            </View>
                            <View style={[styles.section, { alignItems: 'center' }]}>
                                <Text style={styles.text}>CONSUMIDOR NAO IDENTIFICADO</Text>


                            </View>
                            <View style={[styles.section, { alignItems: 'center' }]}>
                                <Text style={styles.text}>Consulta via leitor QR CODE</Text>

                                <Image style={{ width: 100, height: 100 }} src={qr64} />
                                <Text style={styles.text}>Protocolo de Autorização</Text>
                                <Text style={styles.text}>{protocolo}</Text>
                            </View>

                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </div >

    );
};

export default PrintComponent;
