
import { DataGrid } from "@mui/x-data-grid";
import { Pencil, TrashIcon } from "lucide-react";
import { useContext, useState } from "react";
import { Dialog, DialogActions, Button, DialogTitle, DialogContent, DialogContentText, CircularProgress } from "@mui/material";
import axios from 'axios';
import api from './../services/api';
import { toast } from 'react-toastify';
import { useEffect } from "react";
import { context } from "../contexts";
export default function TableUserBond({ data, setReload }) {

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState('')
    const [loading, setLoading] = useState(false)
    const [clients, setClients] = useState([])

    // essa funcao realiza o delete de vinculo
    async function DeleteVinculo() {

        setLoading(true)
        await axios.delete(`${api}vinculo/${selected.CODUSUARIO}/${selected.CNPJ}`)
            .then((res) => {
                toast.success('Vinculo deletado com sucesso')
                setOpen(false)
                setLoading(false)
                setReload(true)
            })
            .catch((error) => {
                toast.error('Erro ao deletar vinculo')
                setLoading(false)
            })
    }


    async function getClients() {

        setLoading(true)

        await axios.get(`${api}cliente`)
            .then((res) => {
                setClients(res.data)

                setLoading(false)

            })
            .catch((error) => {
                toast.error('Erro ao buscar clientes')
                setLoading(false)
            })
    }
    useEffect(() => {
        getClients()

    }, [])


    function RecoverCnpj(value) {
        const filter = clients.filter((item) => item.CNPJ && item.CNPJ === value)
        return filter[0]?.NOME
    }

    //colunas
    const columns = [

        { field: 'CODUSUARIO', headerName: 'Usuário', width: 250 },
        { field: 'CNPJ', headerName: 'CNPJ', width: 150 },
        {
            field: 'NOME',
            headerName: 'Nome',
            width: 300,
            renderCell: (params) => RecoverCnpj(params.row.CNPJ),
        },
        { field: 'CIDADE', headerName: 'Cidade', width: 200 },
        { field: 'TELEFONE', headerName: 'Telefone', width: 130 },

        {
            field: 'Excluir',
            headerName: 'Excluir',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div className="flex text-center mt-4"
                    onClick={() => {
                        setSelected(params.row)
                        setOpen(true)
                    }}
                >
                    <TrashIcon size={20}
                        className='text-red-500 cursor-pointer'
                    />
                </div>
            ),
        },



    ];
    const rows = data.map((item, index) => ({
        ...item,
        id: index + 1 // o campo ide vai ser a posicao do item na lista
    }))
    return (
        <div className="my-4 h-[450px]">
            <DataGrid
                className='max-lg:w-2/3 max-md:w-1/2 max-sm:w-1/3'

                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20]}
                localeText={{
                    footerPaginationRowsPerPage: 'Linhas por página:',
                    footerPaginationPage: 'Página:',
                    footerPaginationNextPage: 'Próxima página',
                    footerPaginationPreviousPage: 'Página anterior',
                    footerRowSelected: (count) => `${count.toLocaleString()} linha(s) selecionada(s)`,
                }}

            />

            <Dialog
                open={open}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Tem certeza que deseja deletar este vinculo?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você está prestes a deletar o vinculo entre {selected.CODUSUARIO}   e o CNPJ {selected.CNPJ}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CANCELAR</Button>
                    <Button
                        onClick={DeleteVinculo}
                    >
                        {
                            loading ? <CircularProgress color='inherit' size={20} /> :

                                'CONFIRMAR'
                        }

                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}