import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import { Card, CircularProgress } from '@mui/material';
import { Link as LinkIcon } from 'lucide-react';
import { SearchIcon } from "lucide-react";
import SearchBar from "../../components/Searchbar";
import List from './List';
import { useState, useContext, useEffect } from "react";
import { context } from "../../contexts";
import api from "../../services/api";
import axios from "axios";
import { toast } from 'react-toastify';

export default function Bonds() {
    const clientes = [
        {
            nome: "Empresa A",
            cnpj: "1234567890001",
            cidade: "São Paulo",
            uf: "SP",
            codigo: "001",
            endereco: "Rua Exemplo, 123",
            telefone: "(11) 1234-5678",
            cep: "01234-567"
        },
        {
            nome: "Empresa B",
            cnpj: "9876543210002",
            cidade: "Rio de Janeiro",
            uf: "RJ",
            codigo: "002",
            endereco: "Avenida Modelo, 456",
            telefone: "(21) 9876-5432",
            cep: "21012-345"
        },
        {
            nome: "Empresa C",
            cnpj: "4567891230003",
            cidade: "Belo Horizonte",
            uf: "MG",
            codigo: "003",
            endereco: "Travessa de Teste, 789",
            telefone: "(31) 3456-7890",
            cep: "30123-456"
        },
        {
            nome: "Empresa D",
            cnpj: "7891234560004",
            cidade: "Salvador",
            uf: "BA",
            codigo: "004",
            endereco: "Praça da Amizade, 987",
            telefone: "(71) 2345-6789",
            cep: "40123-456"
        },
        {
            nome: "Empresa E",
            cnpj: "3216549870005",
            cidade: "Curitiba",
            uf: "PR",
            codigo: "005",
            endereco: "Rua dos Exemplos, 543",
            telefone: "(41) 8765-4321",
            cep: "80234-567"
        },
        {
            nome: "Empresa F",
            cnpj: "6543219870006",
            cidade: "Porto Alegre",
            uf: "RS",
            codigo: "006",
            endereco: "Avenida de Teste, 987",
            telefone: "(51) 5432-1098",
            cep: "90210-123"
        },
        {
            nome: "Empresa G",
            cnpj: "9876543210007",
            cidade: "Recife",
            uf: "PE",
            codigo: "007",
            endereco: "Travessa da Amizade, 456",
            telefone: "(81) 3210-9876",
            cep: "50000-789"
        },
        {
            nome: "Empresa H",
            cnpj: "6549873210008",
            cidade: "Fortaleza",
            uf: "CE",
            codigo: "008",
            endereco: "Praça de Teste, 654",
            telefone: "(85) 2109-8765",
            cep: "60000-123"
        },
        {
            nome: "Empresa I",
            cnpj: "1472583690009",
            cidade: "Brasília",
            uf: "DF",
            codigo: "009",
            endereco: "Quadra Exemplo, 321",
            telefone: "(61) 9876-5432",
            cep: "70000-456"
        },
        {
            nome: "Empresa J",
            cnpj: "2583691470010",
            cidade: "Manaus",
            uf: "AM",
            codigo: "010",
            endereco: "Rua do Teste, 123",
            telefone: "(92) 6543-2109",
            cep: "69000-789"
        }
    ];

    //dados ficticios
    const contadores = [
        {
            codigo: 1,
            usuario: "contador1@example.com",
            cnpj: "12345678901234",
            nome: "Contabilidade A",
            cidade: "Cidade A",
            fone: "(00) 1234-5678"
        },
        {
            codigo: 2,
            usuario: "contador2@example.com",
            cnpj: "98765432109876",
            nome: "Contabilidade B",
            cidade: "Cidade B",
            fone: "(00) 9876-5432"
        },
        {
            codigo: 3,
            usuario: "contador3@example.com",
            cnpj: "56789012345678",
            nome: "Contabilidade C",
            cidade: "Cidade C",
            fone: "(00) 5678-9012"
        },
        {
            codigo: 4,
            usuario: "contador4@example.com",
            cnpj: "34567890123456",
            nome: "Contabilidade D",
            cidade: "Cidade D",
            fone: "(00) 3456-7890"
        },
        {
            codigo: 5,
            usuario: "contador5@example.com",
            cnpj: "90123456789012",
            nome: "Contabilidade E",
            cidade: "Cidade E",
            fone: "(00) 9012-3456"
        }
    ];

    //armazena o usuario e cliente selecionado
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const [searchUser, setSearchUser] = useState('')
    const [searchClient, setSearchClient] = useState('')
    const [listCounters, setListCounters] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [clientsList, setClientsList] = useState([])
    const [filteredListClients, setFilteredListClients] = useState([])
    // quando clico em um usuario ele mostra os clientes vinculados a ele
    // que ficaram armazenados nessa const
    const [vinculeds, setVinculeds] = useState([])
    const [loadVinculeds, setLoadVinculeds] = useState(false)
    const [loading, setLoading] = useState(false)
    // funcao monitora a criacao de vinculo
    const [loadNewVinculed, setLoadNewVinculed] = useState(false)

    async function getCounters() {
        setLoading(true)
        await axios.get(`${api}usuario`)
            .then((res) => {
                /*
                // Usando um objeto para armazenar contadores únicos
                const uniqueCounters = {};
                const data = res.data
                // Iterando pelos dados recebidos da API
                data.forEach(counter => {
                    // Verificando se o CODUSUARIO ainda não está presente no objeto uniqueCounters
                    if (!uniqueCounters[counter.CODUSUARIO]) {
                        // Se não estiver presente, adiciona ao objeto
                        uniqueCounters[counter.CODUSUARIO] = counter;
                    }
                });

                // Convertendo o objeto de contadores únicos novamente em uma lista
                const uniqueCounterList = Object.values(uniqueCounters);
*/
                // Atualizando o estado com a lista de contadores únicos
                setFilteredList(res.data);
                setListCounters(res.data);
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false)
                toast.error('Erro ao buscar contadores')
            })


    }


    async function getClients() {
        setLoading(true)
        await axios.get(`${api}cliente`)
            .then((res) => {
                setClientsList(res.data)
                setFilteredListClients(res.data)

                setLoading(false)
            })
            .catch((error) => {
                toast.error('Erro ao buscar os clientes')
            })
    }


    useEffect(() => {
        getCounters()
        getClients()
    }, [])


    //quando seleciona um usuario ele busca os clientes vinculados a ele
    async function getVinculeds() {
        setVinculeds([])
        setLoadVinculeds(true)

        await axios.get(`${api}vinculo/${selectedUser}`)
            .then((res) => {
                setVinculeds(res.data)
                setLoadVinculeds(false)
            })
            .catch((error) => {
                setLoadVinculeds(false)
                toast.error('Erro ao buscar clientes vinculados')
            })
    }
    useEffect(() => {
        if (selectedUser !== '') {
            getVinculeds()
        }

    }, [selectedUser])

    // FUNÇÃO PARA BUSCAR USUARIO
    function SearchUser() {
        const filter = listCounters.filter((item) => item.NOME.toUpperCase().includes(searchUser.toUpperCase()))
        if (filter.length > 0) {
            setFilteredList(filter)
        } else {
            setFilteredList([])
            toast.warn('Nenhum usuário encontrado')
        }
    }


    //FUNCAO PARA BUSCAR CLIENTES
    function SearchClient() {
        const filter = clientsList.filter((item) => item.NOME.toUpperCase().includes(searchClient.toUpperCase()))
        if (filter.length > 0) {
            setFilteredListClients(filter)
        } else {
            setFilteredListClients([])
            toast.warn('Nenhum cliente encontrado')
        }
    }

    useEffect(() => {

        if (searchUser === '') {
            setFilteredList(listCounters)
        }
        if (searchClient === '') {
            setFilteredListClients(clientsList)
        }
    }, [searchUser, searchClient])



    // funcao cria um novo vinculo entre usuario e cliente
    async function CreateVincule() {
        if (selectedClient !== '' && selectedUser !== '') {
            setLoadNewVinculed(true)
            await axios.post(`${api}vinculo/${selectedUser}/${selectedClient}`)
                .then((res) => {
                    toast.success('Cliente vinculado')
                    setSelectedClient('')
                    setSelectedUser('')
                    getVinculeds()
                    setLoadNewVinculed(false)
                })
                .catch((error) => {
                    toast.error('Erro ao vincular cliente')
                    setLoadNewVinculed(false)
                    console.log(error)
                })
        } else {
            toast.warn('É necessário selecionar cliente e usuário')
        }
    }

    return (
        <div className="flex h-[100vh] w-[100vw] overflow-x-hidden ">
            <Header />
            <div className='mx-8 my-8 max-md:mt-14 '>
                <div className="flex  max-md:grid grid-cols-1 ">
                    <Link to={'/boundClients'}>
                        <button className='mx-12 bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[12rem] min-h-7
                    '

                        >
                            Voltar
                        </button>

                    </Link>
                    <p className="font-semibold text-lg">Vincular usuário com CNPJ de cliente</p>

                </div>

                <div className='mt-8 flex items-center justify-center gap-8 max-md:grid grid-cols-2 max-sm:grid-cols-1'>
                    <Card>
                        <div className=" min-w-[20rem] h-[30rem]  flex flex-col items-center">
                            <span className="text-sm font-semibold mt-2  text-center">Usuários - Selecione abaixo para vincular</span>
                            <div className="flex gap-1  mt-2 ">
                                <SearchBar
                                    placeholder={'Pesquisar'}
                                    value={searchUser}
                                    setSearch={setSearchUser}
                                    onClick={SearchUser}
                                />


                            </div>
                            <div className='overflow-auto my-2'>
                                {
                                    loading ?
                                        <CircularProgress color='warning' size={20} className='mt-20' /> :

                                        filteredList.map((counter) => (
                                            <List key={counter} data={counter} type='user' selected={selectedUser} setSelected={setSelectedUser} />
                                        ))
                                }
                            </div>

                        </div>

                    </Card>
                    <Card>
                        <div className=" min-w-[20rem] h-[30rem]  items-center flex flex-col">
                            <span className="text-sm font-semibold mt-2 text-center">Clientes - Selecione abaixo para vincular</span>
                            <div className="flex gap-1  mt-2 ">
                                <SearchBar
                                    placeholder={'Pesquisar'}
                                    value={searchClient}
                                    setSearch={setSearchClient}
                                    onClick={SearchClient}
                                />
                            </div>
                            <div className='overflow-auto my-2'>
                                {
                                    loading ?
                                        <CircularProgress color='warning' size={20} className='mt-20' />
                                        :

                                        filteredListClients.map((client) => (
                                            <List key={client.codigo} data={client} type='clients' selected={selectedClient} setSelected={setSelectedClient} />
                                        ))
                                }
                            </div>
                        </div>

                    </Card>
                    {
                        loadNewVinculed ?
                            <CircularProgress color='warning' size={20} className='mt-20' />
                            :

                            <LinkIcon
                                onClick={CreateVincule}
                                size={30}
                                className='text-orange-500 cursor-pointer'
                            />
                    }

                    <Card >
                        <div className=" min-w-[20rem] h-[30rem]  items-center flex flex-col">
                            <span className="text-sm font-semibold mt-2 text-center">Clientes vinculados</span>
                            <div className="flex gap-1  mt-2 ">

                            </div>
                            <div className='overflow-auto my-2'>
                                {
                                    loadVinculeds ?
                                        <CircularProgress color='warning' size={20} className='mt-20' /> : ''
                                }
                                {
                                    vinculeds.length === 0 && loadVinculeds === false ?
                                        <p className='mt-20 text-sm'>Nenhum cliente vinculado</p> : ''
                                }
                                {
                                    vinculeds.map((item) => (
                                        <List key={item.codigo} data={item} type='bonds' />
                                    ))
                                }
                            </div>
                        </div>

                    </Card>

                </div>


            </div>
        </div>
    )
}